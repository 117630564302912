import { useAsyncDispatch, useTypedSelector } from "@common/hooks";
import { MenuByTitle, setActiveMenu, userData } from "@common/store";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { NavMenuTitle, newAppRoutes, otherRoutes } from "../../routes";
import { BillsComponent } from "./BillsComponent";
import { BudgetComponent } from "./BudgetComponent";
import { ExternalLinkComponent } from "./ExternalLinkIComponent";
import { NewRouteItem } from "./RouteItem";
import { ReactElement, useEffect } from "react";
import { getAccountsListThunk, getAccountsTypesThunk } from "@common/services";

export function ClientsMenuList(): ReactElement {
  const user = useSelector(userData);
  const dispatch = useDispatch();
  const asyncDispatch = useAsyncDispatch();
  const activeMenu = useTypedSelector(state => state.system.activeMenu);

  useEffect(() => {
    asyncDispatch(getAccountsTypesThunk);
    asyncDispatch(getAccountsListThunk);
  }, []);

  if (user && user?.roleName !== "client") {
    return <></>;
  }

  function onMenuItemClick(title: NavMenuTitle) {
    dispatch(setActiveMenu(MenuByTitle[title]));
  }

  return (
    <Container>
      <BudgetComponent />
      <BillsComponent />
      {newAppRoutes.map(({ ...props }) => (
        <NewRouteItem
          key={props.href}
          {...props}
          active={MenuByTitle[props.title] === activeMenu}
          onClick={() => onMenuItemClick(props.title)}
        />
      ))}
      <FixedContent>
        {otherRoutes.map(({ ...props }) => (
          <ExternalLinkComponent
            key={props.href}
            {...props}
            active={MenuByTitle[props.title] === activeMenu}
            onClick={() => onMenuItemClick(props.title)}
          />
        ))}
      </FixedContent>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 12px;
  max-height: calc(100vh - 290px);
`;

const FixedContent = styled.div`
  flex: 1;
`;
