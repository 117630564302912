import styles from "./ButtonUI.module.scss";
import classnames from "classnames/bind";
import { ReactElement } from "react";

const cln = classnames.bind(styles);

type ButtonUIProps = {
  title?: string
  classname?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  startIcon?: ReactElement
  endIcon?: ReactElement
  type?: "primary" | "white" | "light-gray"
  buttonType?: "button" | "submit" | "reset"
}

export function ButtonUI(props: ButtonUIProps) {
  const {
    title,
    classname,
    onClick,
    startIcon,
    endIcon,
    type = "primary",
    buttonType,
  } = props;

  return (
    <button
      className={cln("button", type, classname)}
      onClick={onClick}
      type={buttonType}
    >
      {startIcon}
      {
        title !== undefined && title !== "" ?
          <span>{title}</span>
          : null
      }
      {endIcon}
    </button>
  );
}
