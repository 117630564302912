import { HTMLInputTypeAttribute, ReactElement } from "react";
import styles from "./InputUI.module.scss";
import classNames from "classnames/bind";
import { useFlag } from "@common/hooks";

const cln = classNames.bind(styles);

export interface InputUIProps {
  name: string;
  icon?: ReactElement;
  label?: string;
  labelIcon?: ReactElement;
  onClick?: () => void;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  ref?: React.LegacyRef<HTMLInputElement>;
  onChange?: (str?: string) => void;
  value?: string;
  disabled?: boolean;
  readOnly?: boolean;
  error?: string;
  touched?: boolean;
  onTouchEnd?: () => void;
  onIconClick?: () => void;

  classes?: {
    wrapper?: string
    container?: string
    input?: string
    icon?: string
    error?: string
  };
  input?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
}

export function InputUI(props: InputUIProps) {
  const {
    icon,
    name,
    label,
    labelIcon,
    onClick,
    placeholder,
    type,
    ref,
    onChange,
    value,
    readOnly,
    disabled,
    error,
    touched,
    classes,
    onTouchEnd,
    onIconClick,
    input,
  } = props;

  const [touchedInput, setTouchedInput] = useFlag(touched);

  function onInputClick() {
    if (onTouchEnd !== undefined) {
      onTouchEnd();
    }
    setTouchedInput();
  }

  return (
    <div className={cln("input-wrapper", classes?.wrapper)}>
      {isLabelWithIcon(label, labelIcon) ? (
        <div className="mb-[10px] flex items-center gap-x-[8px]">
          <label className={cln("label")}>{label}</label>
          {labelIcon}
        </div>
      ) : null}
      {isLabelWithOutIcon(label, labelIcon) ?
        <label className={cln("label", "mb-[10px]")}>{label}</label>
        : null
      }
      <div
        onClick={onClick}
        className={cln(
          "container",
          showError(error, touchedInput || touched) && "error-mode",
          icon !== undefined && "with-icon",
          classes?.container,
        )}
      >
        <input
          ref={ref}
          aria-label={name}
          data-testid={name}
          tabIndex={0}
          type={type}
          name={name}
          onChange={(e) => onChange?.(e.target.value)}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          readOnly={readOnly}
          className={cln("input", classes?.input)}
          onClick={onInputClick}
          {...input}
        />
        {icon ? (
          <div className={cln("icon-wrapper", classes?.icon)} onClick={onIconClick}>
            {icon}
          </div>
        ) : null}

      </div>
      {error && (touchedInput || touched) && (
        <p className={cln("error", classes?.error)}>{error}</p>
      )}
    </div>
  );
}


function isLabelWithIcon(label?: string, labelIcon?: ReactElement) {
  return label !== undefined && labelIcon !== undefined;
}

function isLabelWithOutIcon(label?: string, labelIcon?: ReactElement) {
  return label !== undefined && labelIcon === undefined;
}

function showError(error?: string, touched?: boolean) {
  return error !== undefined && touched;
}
