import { createSlice, PayloadAction, Slice, SliceCaseReducers } from "@reduxjs/toolkit";

type errorType = Error | null | string | string[];

export interface ISettingsUserState {
  loading: boolean;
  error: {
    password: errorType;
    oldPassword: errorType;
    newPassword: errorType;
    email: errorType;
    deleteUser: errorType;
  };
}

const initialState: ISettingsUserState = {
  loading: false,
  error: {
    password: null,
    oldPassword: null,
    newPassword: null,
    email: null,
    deleteUser: null,
  },
};

interface SettingsUserActions extends SliceCaseReducers<ISettingsUserState> {
  deleteUserRequestAction: (state: ISettingsUserState, action: PayloadAction<boolean>) => void
  deleteUserFailureAction: (state: ISettingsUserState, action: PayloadAction<errorType>) => void
  updateEmailRequestAction: (state: ISettingsUserState, action: PayloadAction<boolean>) => void
  updateEmailFailureAction: (state: ISettingsUserState, action: PayloadAction<{
    error?: errorType,
    password?: string[],
    email?: string[]
  }>) => void
  updatePasswordRequestAction: (state: ISettingsUserState, action: PayloadAction<boolean>) => void
  updatePasswordFailureAction: (state: ISettingsUserState, action: PayloadAction<errorType>) => void
}

const settingsUserSlice: Slice<ISettingsUserState, SettingsUserActions, "settingsUser"> = createSlice({
  name: "settingsUser",
  initialState: initialState,
  reducers: {
    deleteUserRequestAction: (state: ISettingsUserState, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    deleteUserFailureAction: (state: ISettingsUserState, action: PayloadAction<errorType>) => {
      state.error.deleteUser = action.payload
    },
    updateEmailRequestAction: (state: ISettingsUserState, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    updateEmailFailureAction: (state: ISettingsUserState, action: PayloadAction<{
      error?: errorType,
      password?: string[],
      email?: string[]
    }>) => {
      const errorMessages = {
        email: action.payload?.error ?? action.payload?.email?.[0] ?? null,
        password: action.payload?.password?.[0] ?? null,
      };
      state.error = { ...state.error, ...errorMessages }
    },
    updatePasswordRequestAction: (state: ISettingsUserState, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    updatePasswordFailureAction: (state: ISettingsUserState, action: PayloadAction<errorType>) => {
      state.error = { ...state.error, oldPassword: action.payload }
    },
  },
  extraReducers: {},
})

export const {
  updatePasswordFailureAction,
  updateEmailRequestAction,
  updateEmailFailureAction,
  updatePasswordRequestAction,
  deleteUserFailureAction,
  deleteUserRequestAction,
} = settingsUserSlice.actions
export const settingsUserReducer = settingsUserSlice.reducer
