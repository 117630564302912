import { Reducer } from "redux";
import {
  addAllTransactionsToChange,
  addApprovedTransactions,
  addFutureListTransactions,
  addFutureTransactions,
  addPeriodicTypes,
  addTransactions,
  addTransactionsLoading,
  addTransactionsToChange,
  clearLastAction,
  createTransactionSuccess,
  deleteTransactionsSuccess,
  editTransactionSuccess,
  redoLastAction,
  removeAllTransactionsToChange,
  removeTransactionsToChange,
  setFailureLoading,
  setLastAction,
  undoLastAction,
} from "../actions";

const INIT_STATE: StateTypes = {
  list: [],
  count: 0,
  futureList: [],
  approvedList: [],
  unapprovedList: [],
  chosen: [],
  periodicTypes: [],
  loading: null,
  errorMessage: "",
  stackActions: {
    undo: [],
    redo: [],
  },
};

export interface TransactionShortView {
  wallet: string;
  periodicity: string;
  contractor?: string;
  date: Date | undefined;
  subcategory?: string;
  incomingAmount?: number;
  outgoingAmount?: number;
  comment?: string;
  isApproved?: boolean;
  paymentReceiptUrl?: string;
  is_system?: boolean
}

export interface Transaction {
  id: string;
  wallet: string;
  walletName?: string;
  periodicity: string;
  periodicityName?: string;
  contractor?: string;
  contractorName?: string;
  date: Date;
  subcategory?: string;
  subcategoryName?: string;
  incomingAmount?: number;
  outgoingAmount?: number;
  comment?: string;
  isApproved?: boolean;
  paymentReceiptUrl?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface PeriodicType {
  id: string;
  alias: string;
  name: string;
}

interface ActionTypes {
  type: string;
  payload?: any;
}

interface StateTypes {
  list: Transaction[];
  count: number;
  futureList: Transaction[];
  approvedList: Transaction[];
  unapprovedList: Transaction[];
  chosen: Transaction[] | undefined;
  periodicTypes: PeriodicType[];
  loading: boolean | null;
  errorMessage: string;
  stackActions: {
    undo: Array<any>;
    redo: Array<any>;
  };
}

export const transactionsReducer: Reducer<StateTypes> = (state: StateTypes = INIT_STATE, action: ActionTypes) => {
  const list = state.approvedList.length ? state.approvedList : state.futureList;
  switch (action.type) {
    case addTransactions.toString():
      return {
        ...state,
        list: action.payload.results,
        count: action.payload.count,
        chosen: [],
      };
    case addTransactionsToChange.toString():
      const addedItem = list.find(item => item.id === action.payload);
      if (addedItem === undefined) {
        return state;
      }
      return {
        ...state,
        chosen: [...(state.chosen ?? []), addedItem],
      };
    case addFutureTransactions.toString():
      return {
        ...state,
        futureList: action.payload,
      };
    case removeTransactionsToChange.toString():
      return { ...state, chosen: state.chosen?.filter(item => item.id !== action.payload) };
    case setFailureLoading.toString():
      return { ...state, errorMessage: action.payload };
    case addPeriodicTypes.toString():
      return { ...state, periodicTypes: action.payload };
    case addAllTransactionsToChange.toString():
      return { ...state, chosen: list };
    case removeAllTransactionsToChange.toString():
      return { ...state, chosen: [] };
    case addApprovedTransactions.toString():
      return {
        ...state,
        approvedList: action.payload.results,
        unapprovedList: [],
        count: action.payload.count,
        chosen: [],
      };
    /* TODO Для неподтвержденных транзакций (в случае необходимости вернуть)*/
    /*  case addUnapprovedTransactions.toString():
      return {
        ...state,
        unapprovedList: action.payload.results,
        approvedList: [],
        count: action.payload.count,
        chosen: [],
      };*/

    case addFutureListTransactions.toString():
      return {
        ...state,
        futureList: action.payload.results,
        approvedList: [],
        count: action.payload.count,
        chosen: [],
      };

    case addTransactionsLoading.toString():
      return {
        ...state,
        loading: action.payload,
      };
    case setLastAction.toString():
      return {
        ...state,
        stackActions: {
          ...state.stackActions,
          undo: [action.payload, ...state.stackActions.undo],
        },
      };
    case undoLastAction.toString():
      const undo = state.stackActions.undo.slice(2);
      return {
        ...state,
        stackActions: {
          undo,
          redo: [state.stackActions.undo[0], ...state.stackActions.redo],
        },
      };
    case redoLastAction.toString():
      const redo = state.stackActions.redo.slice(1);
      return {
        ...state,
        stackActions: {
          ...state.stackActions,
          redo,
        },
      };

    case clearLastAction.toString():
      return {
        ...state,
        stackActions: {
          ...INIT_STATE.stackActions,
        },
      };

    case createTransactionSuccess.toString(): {
      const typeTransaction = state.futureList.length ? "futureList" : "approvedList";

      return {
        ...state,
        [typeTransaction]: [...state[typeTransaction], action.payload],
      };
    }

    case deleteTransactionsSuccess.toString(): {
      const typeTransaction = state.futureList.length ? "futureList" : "approvedList";
      const listAfterDelete = state[typeTransaction].filter(({ id }) => !action.payload.includes(id));

      return {
        ...state,
        [typeTransaction]: listAfterDelete,
      };
    }

    case editTransactionSuccess.toString(): {
      const typeTransaction = state.futureList.length ? "futureList" : "approvedList";
      const activeList = state[typeTransaction];

      const index = activeList.findIndex(item => item.id === action.payload.id);
      const newItems = [...activeList.slice(0, index), action.payload, ...activeList.slice(index + 1)];

      return {
        ...state,
        [typeTransaction]: newItems,
      };
    }

    default:
      return state;
  }
};
