import * as React from "react";
import { Cancel, FormFooter as StyledFormFooter, Submit } from "./styles";

type Props = {
  disabled?: boolean
  rightBtnDisabled?: boolean
  onRightButtonClick?: () => void
  onLeftButtonClick?: () => void
  rightButtonLabel?: string
  leftButtonLabel?: string
  isPrimaryRightButton?: boolean
  className?: string
}

export function FormFooter(props: Props) {

  const {
    disabled,
    onRightButtonClick,
    rightButtonLabel = 'Отменить',
    leftButtonLabel = 'Сохранить',
    onLeftButtonClick,
    rightBtnDisabled,
    isPrimaryRightButton,
    className,
  } = props
  return (
    <StyledFormFooter>
      <Submit
        type="submit"
        onClick={onLeftButtonClick}
        disabled={disabled}
        className={className}
      >
        {leftButtonLabel}
      </Submit>
      {onRightButtonClick !== undefined && (
        <Cancel
          type={isPrimaryRightButton ? "submit" : "button"}
          disabled={rightBtnDisabled}
          onClick={onRightButtonClick}
          $isDisabled={rightBtnDisabled}
          $isPrimary={isPrimaryRightButton}>
          {rightButtonLabel}
        </Cancel>
      )}
    </StyledFormFooter>
  )
}
