import authService from "@auth/service/auth-service";
import { UserData } from "@common/store";
import { createSlice, SerializedError } from "@reduxjs/toolkit";

export interface AuthState {
  isAuth: boolean
  profile: UserData | null
  token: string | null
  error: SerializedError | null
  isPasswordChange: boolean
  loading: boolean
}

const token = localStorage.getItem("session") || ""

const initialState: AuthState = {
  isAuth: false,
  profile: null,
  error: null,
  isPasswordChange: false,
  token: null,
  loading: false,
}

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    changePasswordSuccess: (state): void => {
      state.isPasswordChange = true
    },
    clearStateAction: (state): void => {
      state = initialState
    },
    updateProfileEmail: (state, action): void => {
      if (state.profile) {
        state.profile = { ...state.profile, email: action.payload }
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(authService.login.pending, state => {
        state.loading = true
      })
      .addCase(authService.login.fulfilled, (state, { payload: { token, ...profile } }) => {
        state.profile = profile
        state.token = token
        state.isAuth = true
        state.loading = false
        state.error = null
      })
      .addCase(authService.login.rejected, (state, action) => {
        state.loading = false
        state.token = null
        state.error = action.error
      })
      .addCase(authService.sendLinkChangePassword.pending, state => {
        state.loading = true
      })
      .addCase(authService.sendLinkChangePassword.fulfilled, (state, action) => {
        state.isPasswordChange = true
        state.isAuth = false
        state.loading = false
        state.error = null
      })
      .addCase(authService.sendLinkChangePassword.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })
      .addCase(authService.signUpFromData.pending, (state, action) => {
        state.loading = true
      })
      .addCase(authService.signUpFromData.rejected, (state, action) => {
        state.isAuth = false
        state.loading = false
        state.error = action.error
      })
      .addCase(authService.changePassword.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })
      .addCase(authService.logout.pending, state => {
        state.loading = true
      })
      .addCase(authService.logout.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.isAuth = false
        state.profile = null
        state.token = null
      })
  },
})

export const { changePasswordSuccess, clearStateAction, updateProfileEmail } = authSlice.actions

export const authReducer = authSlice.reducer
