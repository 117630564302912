import classNames from "classnames/bind";
import styles from "./SectionTitle.module.scss";
import { CSSProperties } from "react";

const cln = classNames.bind(styles);

type Props = {
  title: string;
  classNames?: string;
  styles?: CSSProperties
};
export function SectionTitle({ title, classNames, styles }: Props) {
  return <h1 className={cln("title", classNames)} style={styles}>{title}</h1>;
}
