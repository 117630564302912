import { API } from "@common/api";
import { ContractorEditType, IContractor } from "@common/store";

export type ContractorTransportType = {
  getContractors: (budgetId: string) => Promise<IContractor[]>
  createContractor: (dto: ContractorEditType) => Promise<IContractor>
  updateContractor: (dto: ContractorEditType & { id: string }) => Promise<IContractor>
  deleteContractor: (id: string) => Promise<void>
}
export const ContractorTransport: ContractorTransportType = {
  getContractors: async (budgetId: string): Promise<IContractor[]> => {
    const { data } = await API.get<IContractor[]>("/contractors/", { params: { budget: budgetId } })
    // const { data } = await API.get<IContractor[]>("/contractors/", { params: { budget: budgetId } })
    return data
  },
  createContractor: async (dto: ContractorEditType): Promise<IContractor> => {
    const { data } = await API.post<IContractor>("/contractors/", { ...dto, templates: [] })
    // const { data } = await API.post<IContractor>("/contractors/", { ...dto, templates: [] })
    return data
  },
  updateContractor: async ({ id, ...dto }: ContractorEditType & { id: string }): Promise<IContractor> => {
    const { data } = await API.put<IContractor>(`/contractors/${id}`, { ...dto, templates: [] })
    // const { data } = await API.put<IContractor>(`/contractors/${id}`, { ...dto, templates: [] })
    return data
  },
  deleteContractor: async (id: string): Promise<void> => {
    return await API.delete(`/contractors/${id}/`)
    // return await API.delete(`/contractors/${id}/`)
  },
}
