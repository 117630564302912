import { useAsyncDispatch, useTypedSelector } from "@common/hooks";
import { fetchUserDataThunk, NAV_BAR_MENU, setActiveMenu, userData, userError, userLoading } from "@common/store";
import { CSSProperties, PropsWithChildren, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { Loader } from "../Loader";
import { NavBar } from "../NavBar";
import { FirstSocialAuthModal } from "../deprecated/FirstSocialAuthModal/FirstSocialAuthModal";

export const LayoutWrapper = ({ children, styles }: PropsWithChildren<{ styles?: CSSProperties}>) => {
  const dispatch = useAsyncDispatch();
  const activeMenu = useTypedSelector(state => state.system.activeMenu);
  const user = useSelector(userData);
  const isLoadingUser = useSelector(userLoading);
  const error = useSelector(userError);

  useEffect(() => {
    if (!user && !isLoadingUser && !error) {
      dispatch(fetchUserDataThunk);
    }
    if (user && user.roleName === "client" && activeMenu === null) {
      dispatch(setActiveMenu(NAV_BAR_MENU.BUDGET));
      return;
    }
    if (user && activeMenu === null) {
      dispatch(setActiveMenu(NAV_BAR_MENU.USERS));
    }
  }, [dispatch, user, isLoadingUser, error, activeMenu]);

  if (error) {
    return <Wrapper />;
  }

  return (
    <StyledPageContainer style={styles}>
      <NavBar />
      <Wrapper>
        {isLoadingUser
          ? <Loader />
          : <>{children}</>
        }
      </Wrapper>
      <FirstSocialAuthModal />
    </StyledPageContainer>
  );
};

export const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 30px 20px 20px;
  grid-column-start: 2;
  grid-column-end: 3;
  max-height: 100svh;
`;

const StyledPageContainer = styled.div`
  display: grid;
  grid-template-columns: 220px 1fr;
  box-sizing: border-box;
  overflow: hidden;
  height: 100svh;
  scrollbar-width: none;
`;
