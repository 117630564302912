import { Button, Card as MuiCard, IconButton, Modal } from "@material-ui/core";
import styled from "styled-components";

export const Paper = styled(Modal)`
  outline: 0;

  &:focus {
    outline: 0;
  }

  && {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Card = styled(MuiCard)<{ width?: number; minHeight?: number; minWidth?: number; small?: boolean }>`
  &.MuiCard-root {
    padding: 32px;
    position: relative;
    overflow: visible;
    border: 0;
    outline: none;

    ${p =>
      p.small &&
      `
      padding: 24px 16px;
    `}

    width: ${p => p.width}px;
    min-width: ${p => p.minWidth}px;
    min-height: ${p => p.minHeight}px;
  }
`;

export const WarningCard = styled(MuiCard)<{ width?: number; height?: number }>`
  &.MuiCard-root {
    padding: 32px;
    position: relative;
    overflow: visible;
    border: 0;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;

    width: ${p => p.width}px;
    height: ${p => p.height}px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  outline: 0;

  &:focus {
    outline: 0;
  }
`;

export const ModalTitle = styled.h5`
  font-family: Manrope, Inter, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.3;
  color: ${p => p.theme.colors.darkText};
  padding: 0 16px 0 0;
  margin-bottom: 12px;
`;

export const WarningModalTitle = styled.h5`
  font-family: Manrope, Inter, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.3;
  color: ${p => p.theme.colors.darkText};
  text-align: center;
`;

export const MarkedTitle = styled(WarningModalTitle)<{ type?: "danger" | "warning" | "info" | "success" }>`
  margin-bottom: 20px;

  ${p =>
    p?.type === "danger" &&
    `
     color: #FF3A3A;
 `};
  ${p =>
    p?.type === "warning" &&
    `
     color: #A76400;
 `};
  ${p =>
    p?.type === "success" &&
    `
     color: #188875;
 `};
  ${p =>
    p?.type === "info" &&
    `
     color: #0037FC; 
 `};
`;
export const ModalSubtitle = styled.div<{ centerSubtitle?: boolean }>`
  font-family: Manrope, Inter, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: ${p => p.theme.colors.lightText};
  padding: 0 16px 0 0;

  display: inline-block;
  white-space: pre-wrap;
  margin-bottom: auto;

  ${p =>
    p.centerSubtitle &&
    `
  text-align: center;
 `}
`;
export const Close = styled(IconButton)`
  && {
    background-color: ${p => p.theme.colors.white};
    border-radius: 6px;
    border: 1px solid transparent;
    padding: 12px;

    position: absolute;
    top: 24px;
    right: 32px;
    z-index: 100;

    &:hover {
      border: 1px solid ${p => p.theme.colors.lightnessGrey};
    }
  }
`;

export const Submit = styled(Button)`
  &.MuiButton-root {
    font-size: 16px;
    padding: 8px 16px;
    background-color: ${p => p.theme.colors.main};
    color: ${p => p.theme.colors.white};
    font-weight: 500;
    font-family: Manrope, Inter, sans-serif;
    text-align: center;
    border-radius: 6px;
    border: 1px solid transparent;
    width: 100%;

    &:hover {
      background-color: ${p => p.theme.colors.main};
      color: ${p => p.theme.colors.white};
    }
    &.Mui-disabled {
      color: ${p => p.theme.colors.white};
    }
    .MuiButton-label {
      text-transform: none;
    }
  }
`;

export const Cancel = styled(Button)<{ $isPrimary?: boolean; $isDisabled?: boolean }>`
  &.MuiButton-root {
    padding: 8px 16px;
    background-color: ${p => p.theme.colors.white};
    color: ${p => p.theme.colors.contentSecondary};
    font-weight: 500;
    font-family: Manrope, Inter, sans-serif;
    text-align: center;
    border-radius: 6px;
    border: 1px solid ${p => p.theme.colors.lightGrey};
    width: 100%;
    font-size: 16px;

    ${p =>
      p?.$isPrimary &&
      `
       background-color: #13BDCE;
       color: white;
       border: 1px solid transparent;
       
       &:hover {
       background-color: #13BDCE;
       color: white;
       }
   `}
    ${p =>
      p?.$isPrimary &&
      p?.$isDisabled &&
      `
       background-color: #13BDCE;
       color: white;
       
       &.Mui-disabled {
         color: white;
       } 
        
       &:hover {
        background-color: #13BDCE;
        color: white;
       }
   `}
    .MuiButton-label {
      text-transform: none;
    }
  }
`;
export const FormFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
`;

export const ChildrenContainer = styled.div<{ withMargin?: boolean }>`
  width: 100%;

  ${p =>
    p.withMargin &&
    `
    margin-bottom: 12px;
  `}
`;
