import Icon from "@assets/icons/subscribeBanner.svg";
import { useTypedSelector } from "@common/hooks";
import { OneRowTooltip as Tooltip } from "../../../ui";
import { ExitButton, FooterWrapper, StyledEmail, SubscriptionBanner } from "./styles";
import { useDispatch } from "react-redux";
import { resetSystem } from "@common/store";
import { localStorageService } from "@common/services/localStorage.service";

export function Footer() {
  const user = useTypedSelector(state => state.auth.profile);
  const dispatch = useDispatch();

  function onLogout() {
    dispatch(resetSystem({}));
    localStorageService.delete("currentBudgetId");
    localStorageService.delete("session");
  }

  return (
    <FooterWrapper>
      {user?.endOfSubscription === null ||
        (user?.endOfSubscription === undefined && <SubscriptionBanner to="/subscription" children={<Icon />} />)}
      <ExitButton to="/auth/login" onClick={onLogout}>Выход</ExitButton>
      <Tooltip placement="top" title={user?.email}>
        <StyledEmail>{user?.email}</StyledEmail>
      </Tooltip>
    </FooterWrapper>
  );
}
