import { StatusCode } from "@assets/index";
import { API_V2 } from "@common/api";
import { getAccountsListThunk } from "@common/services";
import { normalizeError } from "@common/utils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createAction } from "redux-actions";
import { PeriodicType, setNotification } from "../reducers";
import { transactionsApproved, transactionsFutureList } from "../selectors";
import { TransactionTransport } from "@common/transports";

export interface ISplitTransaction {
  subcategory: string;
  amount: string;
  comment?: "";
}

interface ISplitTransactions {
  id: string;
  splitTransactions?: { newTransactions: ISplitTransaction[] };
}

export const addTransactions = createAction("ADD_TRANSACTIONS_LIST");
export const addTransactionsLoading = createAction("ADD_TRANSACTIONS_LIST_LOADING");
export const addPeriodicTypes = createAction("ADD_PERIODIC_TYPES");
export const addFutureTransactions = createAction("ADD_FUTURE_TRANSACTIONS");
export const addApprovedTransactions = createAction("ADD_APPROVED_TRANSACTIONS_LIST");
/* TODO Для неподтвержденных транзакций (в случае необходимости вернуть)*/
// export const addUnapprovedTransactions = createAction('ADD_UNAPPROVED_TRANSACTIONS_LIST');
export const addFutureListTransactions = createAction("ADD_FUTURELIST_TRANSACTIONS_LIST");

export const setFailureLoading = createAction("SET_FAILURE_LOADING");
export const addAllTransactionsToChange = createAction("ADD_ALL_TRANSACTIONS_TO_CHANGE");
export const removeAllTransactionsToChange = createAction("REMOVE_ALL_TRANSACTIONS_TO_CHANGE");

export const setLastAction = createAction("SET_LAST_ACTION");
export const undoLastAction = createAction("UNDO_LAST_ACTION");
export const redoLastAction = createAction("REDO_LAST_ACTION");
export const clearLastAction = createAction("CLEAR_LAST_ACTION");

export const deleteTransactionsSuccess = createAction("DELETE_TRANSACTIONS_SUCCESS");

export const editTransactionSuccess = createAction("EDIT_TRANSACTION_SUCCESS");

export const createTransactionSuccess = createAction("CREATE_TRANSACTION_SUCCESS");

export const getTransactionsBySubcategoryIdThunk = createAsyncThunk<void, { subcategory_id: string }>(
  "GET_TRANSACTIONS_BY_SUBCATEGORY_ID",
  async ({ subcategory_id }, thunkAPI) => {
    thunkAPI.dispatch(addTransactionsLoading(true));
    API_V2.get("/transactions", { params: { subcategory_id: subcategory_id } })
      .then(({ data }: any) => {
        thunkAPI.dispatch(addTransactions(data));
      })
      .catch(error => thunkAPI.dispatch(setFailureLoading(error.message)))
      .finally(() => {
        thunkAPI.dispatch(addTransactionsLoading(false));
      });
  },
);

export const getTransactionListAction = ({
  page = 1,
  account = "",
  /* TODO Для неподтвержденных транзакций (в случае необходимости вернуть)*/
  // isApproved = true,
  budget = "",
  planned = false,
  query = "",
  ordering = "",
}) => {
  return dispatch => {
    API_V2.get(
      "transactions",
      // ?${
      //   budget ? `budget=${budget}&account=${account}&` : ""
      // }page=${page}&future_spending=${planned}&query=''${query}&ordering=${ordering}`
      {
        params: {
          ...(budget !== "") && {
            budget: budget,
            account: account,
          },
          page: page,
          future_spending: planned,
          // query: query,
          ordering: ordering,
        }
      }
    )
      .then((response: any) =>
        !planned
          ? dispatch(addApprovedTransactions(response.data))
          : dispatch(addFutureListTransactions(response.data)),
      )
      .catch(error => dispatch(setFailureLoading(error.message)));
  };
};

export const addTransactionsToChange = createAction("ADD_TRANSACTIONS_TO_CHANGE");
export const removeTransactionsToChange = createAction("REMOVE_TRANSACTIONS_TO_CHANGE");

export const deleteTransaction = value => async dispatch => {
  try {
    await API_V2.delete(`/transactions/${value.id}`);
    dispatch(setNotification({ type: "success", message: "Транзакция успешно удалена" }));

    dispatch(setLastAction({ value, type: "remove" }));
    dispatch(getAccountsListThunk);
  } catch (error) {
    dispatch(setFailureLoading((error as any).message));
  }
};

export const deleteTransactions = (transactionIds: string[]) => async dispatch => {
  const messageSuccess = transactionIds.length > 1 ? "Транзакции успешно удалены" : "Транзакция успешно удалена";
  try {
    await API_V2.post(`/transactions/remove_many`, { transactionIds });
    dispatch(setNotification({ type: "success", message: messageSuccess }));

    dispatch(deleteTransactionsSuccess(transactionIds));

    dispatch(setLastAction({ value: transactionIds, type: "remove" }));
    dispatch(TransactionTransport.getAllPageLess({}));
  } catch (error) {
    dispatch(setFailureLoading((error as any).message));
  }
};

export const restoreTransactions = transactionIds => async dispatch => {
  const messageSuccess =
    transactionIds.length > 1 ? "Транзакции успешно восстановлены" : "Транзакция успешно восстановлена";
  try {
    await API_V2.post(`/transactions/restore_many`, { transactionIds });
    dispatch(setNotification({ type: "success", message: messageSuccess }));

    dispatch(setLastAction({ value: transactionIds, type: "restore" }));
  } catch (error) {
    dispatch(setFailureLoading((error as any).message));
  }
};

export const approveTransaction = id => dispatch => {
  API_V2.patch(`/transactions/${id}`, {
    isApproved: true,
  })
    .then(() => dispatch(setNotification({ type: "success", message: "Транзакция подтверждена" })))
    .catch(error => dispatch(setFailureLoading(error.message)));
};

export const createTransaction = value => async dispatch => {
  try {
    const response = await API_V2.post(`/transactions`, { ...value });
    dispatch(setNotification({ type: "success", message: "Транзакция успешно создана" }));

    dispatch(createTransactionSuccess(response.data));
    dispatch(setLastAction({ value: response.data, type: "create" }));
    return response.data;
  } catch (error) {
    const message =
      (error as any).response.status === StatusCode.BAD_REQUEST
        ? "Транзакция не по кредитному счету не может уводить его в минус"
        : (error as any).message;
    dispatch(setNotification({ type: "error", message: message }));
    dispatch(setFailureLoading((error as any).message));
  }
};

export const getPeriodicTypesThunk = createAsyncThunk<PeriodicType[] | undefined, void>(
  "GET_PERIODIC_TYPES",
  async (_, thunkAPI) => {
    try {
      const { data } = await API_V2.get<PeriodicType[]>("/transactions/periodic-types");
      // const { data } = await API.get<PeriodicType[]>("/transactions/periodic-types/");
      return data;
    } catch (err) {
      const error = normalizeError(err);
      thunkAPI.dispatch(setFailureLoading(error.message));
    }
  },
)();

export const getAllTransactions = () => dispatch => {
  dispatch(addTransactionsLoading(true));
  API_V2.get("/transactions")
    .then(({ data }: any) => {
      dispatch(addTransactions(data));
    })
    .catch(error => dispatch(setFailureLoading(error.message)))
    .finally(() => {
      dispatch(addTransactionsLoading(false));
    });
};

export const importTransactionsFile = ({ file }) => dispatch => {
  const params = new FormData();
  params.append("file", file);
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  API_V2.post("/transactions/load_data", params, config)
    .then(() => dispatch(setNotification({ type: "success", message: "Файл импортирован успешно" })))
    .catch(() => dispatch(setNotification({ type: "error", message: "Неправильный формат файла" })));
};

export const getTransactionFutureList = ({
  page = 1,
  account,
  /* TODO Для неподтвержденных транзакций (в случае необходимости вернуть)*/
  // isApproved = false,
  budget,
  planned = true,
  query = "",
}) => {
  return dispatch => {
    const params = {
      params: {
        budget,
        account,
        page,
        /* TODO Для неподтвержденных транзакций (в случае необходимости вернуть)*/
        // is_approved: isApproved,
        future_spending: planned,
        query,
      },
    };

    API_V2.get(`/transactions`, params)
      .then((response: any) => {
        dispatch(addFutureTransactions(response.data.results));
      })
      .catch(error => dispatch(setFailureLoading(error.message)));
  };
};

export const editTransaction = (data, id: string) => async (dispatch, getState) => {
  const typeTransactionSelector = data.planned ? transactionsFutureList : transactionsApproved;
  const currentTransactionsList = typeTransactionSelector(getState());
  const currentTransaction = currentTransactionsList.find(transaction => transaction.id === id);

  try {
    const response = await API_V2.put(`/transactions/${id}`, { ...data });

    dispatch(editTransactionSuccess(response.data));
    dispatch(setNotification({ type: "success", message: "Транзакция успешно изменена" }));
    dispatch(setLastAction({ value: currentTransaction, type: "edit" }));
  } catch (error) {
    dispatch(setFailureLoading((error as any).message));
  }
};

export const splitTransaction = ({ id, splitTransactions }: ISplitTransactions) => async dispatch => {
  try {
    await API_V2.post(`/transactions/${id}/split`, splitTransactions);

    dispatch(setNotification({ type: "success", message: "Транзакция успешно разделена" }));
    dispatch(clearLastAction());
  } catch (error) {
    dispatch(setNotification({ type: "error", message: "Ошибка разделения транзакции" }));
  }
};
