import { TextFieldProps } from "@material-ui/core/TextField/TextField";
import { FieldInputProps } from "react-final-form";
import { TextField } from "./styles";
import { ReactElement } from "react";
import { InputProps as StandardInputProps } from "@material-ui/core/Input/Input";

interface Props<T> {
  input: FieldInputProps<T>;
  label?: string;
  placeholder?: string;
  settings?: TextFieldProps;
  inputProps?: StandardInputProps["inputProps"];
  InputProps?: Partial<StandardInputProps>
}

export function FormInputText<T>(props: Props<T>): ReactElement {
  const {
    input,
    placeholder,
    label,
    settings,
    inputProps,
    InputProps,
  } = props;

  return (
    <TextField
      {...(settings !== undefined && { settings })}
      name={input.name}
      value={input.value}
      onChange={input.onChange}
      id={input.name}
      inputProps={inputProps}
      label={label}
      type="text"
      placeholder={placeholder}
      InputProps={InputProps}
    />
  );
}
