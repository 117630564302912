import authService from "@auth/service/auth-service";
import { useAsyncDispatch } from "@common/hooks";
import { validateEmail } from "@common/utils";
import { Checkbox, IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { Social } from "../common/Social";
import {
  Box,
  Container,
  DocumentLink,
  Form,
  FormControlLabel,
  Link,
  Policy,
  Submit,
  TextField,
  Title,
  Wrapper,
} from "../common/styles";

export type LoginFormType = {
  email: string
  password: string
  stayAuthed: boolean
}

export const Login = () => {
  const [fieldType, setFieldType] = useState<"password" | "text">("password");
  const asyncDispatch = useAsyncDispatch();
  const navigate = useNavigate();

  function login({ email, password, stayAuthed }: LoginFormType) {
    asyncDispatch(authService.login({ email, password, saveRefreshToken: stayAuthed }))
      .then((response) => {
        if ((response as any)?.error?.message === "Rejected") {
          return;
        }
        navigate("/")
      });

  }

  const renderForm = ({ handleSubmit }) => {
    return (
      <Form onSubmit={handleSubmit}>
        <Field<string> name="email" validate={validateEmail}>
          {({ input, meta }) => (
            <TextField
              name={input.name}
              value={input.value}
              onChange={input.onChange}
              id="email"
              label="E-mail"
              type="email"
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => input.onBlur(e)}
              autoComplete="current-email"
              error={meta.invalid && meta.touched}
              helperText={meta.pristine ? "" : meta.error}
            />
          )}
        </Field>
        <Field<string> name="password" validate={value => !value && "Required"}>
          {({ input, meta }) => (
            <TextField
              name={input.name}
              value={input.value}
              onChange={input.onChange}
              id="password"
              label="Пароль"
              type={fieldType}
              autoComplete="current-password"
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => input.onBlur(e)}
              error={meta.invalid && meta.touched}
              helperText={meta.pristine ? "" : meta.error}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={() => setFieldType(prevState => (prevState === "password" ? "text" : "password"))}
                    >
                      {fieldType === "text" ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Field>
        <Box>
          <Field<boolean> name="stayAuthed" type="checkbox">
            {({ name, input }) => (
              <FormControlLabel
                control={<Checkbox name={name} value={input.value} onChange={input.onChange} />}
                label="Не выходить из системы"
              />
            )}
          </Field>
          <Link to="/auth/change-password">Не помните пароль?</Link>
        </Box>
        <Policy>
          Выполняя вход, вы принимаете{" "}
          <DocumentLink href="https://store.savinger.ru/landing/privacy_policy.pdf" target="__blanc">
            Политику конфиденциальности
          </DocumentLink>{" "}
          и{" "}
          <DocumentLink href="https://store.savinger.ru/landing/terms_of_use.pdf" target="__blanc">
            Пользовательское соглашение
          </DocumentLink>{" "}
          Savinger
        </Policy>
        <Submit type="submit">Вход</Submit>
      </Form>
    );
  };

  return (
    <Wrapper>
      <Container>
        <Title>Вход</Title>
        <FinalForm<LoginFormType> onSubmit={login} render={renderForm} />
        <Social />
        <p>
          Еще нет аккаунта? <Link to="/auth/signup">Присоединяйтесь</Link>
        </p>
      </Container>
    </Wrapper>
  );
};
