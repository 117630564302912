import { NavMenuTitle } from "@common/components/NavBar/routes";
import { createSlice, PayloadAction, Slice, SliceCaseReducers } from "@reduxjs/toolkit";

export type SystemStateType = {
  activeMenu: NAV_BAR_MENU | null
}
type MenuByTitleType = Record<NavMenuTitle, NAV_BAR_MENU>

export enum NAV_BAR_MENU {
  BUDGET = "BUDGET",
  BILLS = "BILLS",
  ANALYTICS = "ANALYTICS",
  CONTRACTORS = "CONTRACTORS",
  SETTINGS = "SETTINGS",
  SUBSCRIPTION = "SUBSCRIPTION",
  USERS = "USERS",
  ARTICLES = "ARTICLES",
  NOTIFICATIONS = "NOTIFICATIONS",
  MANAGER_REQUEST = "MANAGER_REQUEST",
  VISIT_WEB = "VISIT_WEB",
  NOT_SELECTED = "NOT_SELECTED",
}

export const MenuByTitle: MenuByTitleType = {
  Бюджет: NAV_BAR_MENU.BUDGET,
  Счета: NAV_BAR_MENU.BILLS,
  Aналитика: NAV_BAR_MENU.ANALYTICS,
  Статьи: NAV_BAR_MENU.ARTICLES,
  "Запросы менеджеров": NAV_BAR_MENU.MANAGER_REQUEST,
  Настройки: NAV_BAR_MENU.SETTINGS,
  Контрагенты: NAV_BAR_MENU.CONTRACTORS,
  Оповещения: NAV_BAR_MENU.NOTIFICATIONS,
  "Перейти на сайт": NAV_BAR_MENU.VISIT_WEB,
  Подписка: NAV_BAR_MENU.SUBSCRIPTION,
  Пользователи: NAV_BAR_MENU.USERS,
};

const INIT_STATE: SystemStateType = {
  activeMenu: null,
};

interface SystemActions extends SliceCaseReducers<SystemStateType> {
  setActiveMenu: (state: SystemStateType, action: PayloadAction<NAV_BAR_MENU | null>) => void;
}

const systemSlice: Slice<SystemStateType, SystemActions, "system"> = createSlice({
  name: "system",
  initialState: INIT_STATE,
  reducers: {
    setActiveMenu: (state, action: PayloadAction<NAV_BAR_MENU | null>) => {
      state.activeMenu = action.payload;
    },
    resetSystem: (state) => {
      state.activeMenu = null;
    },
  },
  extraReducers: {},
});

export const { setActiveMenu, resetSystem } = systemSlice.actions;
export const systemReducer = systemSlice.reducer;
